export const mockTokenData = [
  {
    date: "2024-01-01",
    token_count: [
      {
        service_name: "Backend",
        moduleName: "OCR",
        token_count: 200,
      },
      {
        service_name: "Frontend",
        moduleName: "OCR",
        token_count: 180,
      },
     
    ],
  },
  {
    date: "2024-01-02",
    token_count: [
      {
        service_name: "Backend",
        moduleName: "Open AI",
        token_count: 220,
      },
     
      {
        service_name: "RBAC",
        moduleName: "Open AI",
        token_count: 90,
      },
      {
        service_name: "PlaceHolder API",
        moduleName: "Open AI",
        token_count: 85,
      }
    ],
  },
  {
    date: "2024-01-03",
    token_count: [
      {
        service_name: "Backend",
        moduleName: "OCR",
        token_count: 210,
      },
      {
        service_name: "Frontend",
        moduleName: "OCR",
        token_count: 160,
      },
      {
        service_name: "Webhook",
        moduleName: "OCR",
        token_count: 100,
      },
      {
        service_name: "Auth",
        moduleName: "OCR",
        token_count: 85,
      },
      {
        service_name: "RBAC",
        moduleName: "OCR",
        token_count: 50,
      },
      {
        service_name: "PlaceHolder API",
        moduleName: "OCR",
        token_count: 60,
      }
    ],
  },
  {
    date: "2024-01-04",
    token_count: [

      {
        service_name: "Auth",
        moduleName: "Open AI",
        token_count: 110,
      },
      {
        service_name: "RBAC",
        moduleName: "Open AI",
        token_count: 65,
      },
      {
        service_name: "PlaceHolder API",
        moduleName: "Open AI",
        token_count: 95,
      }
    ],
  }
];


 export  const mockDocStats = [
    { date: "2024-01-01", status: "SUCCEEDED", processed: 300 },
    { date: "2024-01-02", status: "FAILED", processed: 50 },
    { date: "2024-01-02", status: "Processing", processed: 54 },
];